var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from "react";
import { AccountsRecords } from "./AccountsRecords";
import { makeStyles } from "@fluentui/react-components";
import { ContactsRecords } from "./ContactsRecords";
import { CallBackRequestsRecords } from "./CallBackRequestsRecords";
import logger from "../../../utils/Logger";
import { PhoneCallRecord } from "./PhoneCallRecord";
import { useDynamics } from "../../../services/dynamics/DynamicsProvidor";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "0 20px",
        gap: "0.5rem",
        width: "100%",
        alignItems: "center",
    },
});
export const Records = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const styles = useStyles();
    const dynamics = useDynamics();
    const onOpenRecord = useCallback((entityType, entityId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dynamics.openRecord(entityType, entityId);
        }
        catch (error) {
            logger.error("[Records.tsx]", "Error opening record", error);
        }
    }), [dynamics]);
    return (React.createElement("div", { className: styles.root },
        React.createElement(PhoneCallRecord, { phoneCall: (_c = (_b = (_a = dynamics.matchingRecords) === null || _a === void 0 ? void 0 : _a.phoneCall) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : undefined, title: "Phone Call", onOpenRecord: onOpenRecord }),
        React.createElement(AccountsRecords, { accounts: (_e = (_d = dynamics.matchingRecords) === null || _d === void 0 ? void 0 : _d.accounts) === null || _e === void 0 ? void 0 : _e.data, title: "Accounts", onOpenRecord: onOpenRecord }),
        React.createElement(ContactsRecords, { accounts: (_g = (_f = dynamics.matchingRecords) === null || _f === void 0 ? void 0 : _f.accounts) === null || _g === void 0 ? void 0 : _g.data, title: "Contacts", onOpenRecord: onOpenRecord }),
        React.createElement(CallBackRequestsRecords, { callBackRequests: (_j = (_h = dynamics.matchingRecords) === null || _h === void 0 ? void 0 : _h.callBacks) === null || _j === void 0 ? void 0 : _j.data, title: "CallBack Requests", onOpenRecord: onOpenRecord })));
};
