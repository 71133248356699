var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CIF_EVENT } from "./types/IMicrosoft";
import { EnumScreenPopTo } from "./types/IDynamicsContext";
import { initialise as initDynamics } from "./dynamics";
import logger from "../../utils/Logger";
import { useWebexContactCentre } from "../webex/contactcenter/WebexContactCentreProvider";
import { CONTACT_DIRECTION, ROUTING_TYPE } from "../webex/contactcenter/models/IRoutingMessage";
import { CallDirection, ParticipationTypeMask, } from "./types/IPhoneCall";
import { EnquiryType } from "./types/ICallBackRequest";
import { SearchType } from "./types/IXrm";
import { CALL_STATE } from "../webex/contactcenter/models/Enums";
const DynamicsContext = createContext({
    isInitialising: true,
    isError: false,
    isReady: false,
    matchingRecords: undefined,
    openRecord: () => __awaiter(void 0, void 0, void 0, function* () {
        throw new Error("Dynamics not initialised");
    }),
    callHistory: [],
    customParams: {},
});
const phoneCallSelects = [
    "rdo_webexid",
    "rdo_wrapupcode",
    "subject",
    "phonenumber",
    "directioncode",
    "statuscode",
    "statecode",
    "description",
    "actualstart",
    "actualend",
];
const TIMEOUT_MS_SEARCH_ENTITY_TO_ARRAY = 5000;
export const DynamicsProvidor = ({ children }) => {
    const [Microsoft, setMicrosoft] = React.useState(undefined);
    const [Xrm, setXrm] = React.useState(undefined);
    const [isError, setIsError] = React.useState(false);
    const [isInitialising, setIsInitialising] = React.useState(true);
    const [isReady, setIsReady] = React.useState(false);
    const [msEnv, setMsEnv] = React.useState(undefined);
    const [customParams, setCustomParams] = React.useState({});
    const webex = useWebexContactCentre();
    const userIdRef = React.useRef(undefined);
    const userDisplayNameRef = React.useRef(undefined);
    const searchResultRef = React.useRef(undefined);
    const activeRecordsRef = React.useRef(undefined);
    const [matchingRecords, setMatchingRecords] = useState(undefined);
    const [callHistory, setCallHistory] = useState([]);
    const teamsRef = useRef([]);
    const descriptionsCache = useRef(new Map()); // Caches descriptions by phone number
    const screenPopToRef = useRef(EnumScreenPopTo.MATCHING_RECORD);
    function searchEntityToArrayPromise(entityName, query) {
        return __awaiter(this, void 0, void 0, function* () {
            const resultString = yield Microsoft.CIFramework.searchAndOpenRecords(entityName, query, true);
            const result = JSON.parse(resultString);
            const asArray = result.hasOwnProperty("0") ? Object.values(result) : [];
            return asArray;
        });
    }
    // Throws an exception if the request times out
    function searchEntityToArray(_a) {
        return __awaiter(this, arguments, void 0, function* ({ entityName, query, timeoutMs = TIMEOUT_MS_SEARCH_ENTITY_TO_ARRAY }) {
            const fetchPromise = searchEntityToArrayPromise(entityName, query);
            const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error(`Request timed out after ${timeoutMs}ms`)), timeoutMs));
            const results = yield Promise.race([fetchPromise, timeoutPromise]);
            const realResult = Array.isArray(results) ? results : [];
            return realResult;
        });
    }
    const getCustomerDescription = (phoneNumber) => __awaiter(void 0, void 0, void 0, function* () {
        const searchResults = yield searchCallerId(phoneNumber);
        if (searchResults.accounts.length === 1 && searchResults.accounts[0].contacts.length === 1) {
            return searchResults.accounts[0].contacts[0].fullname + " - " + searchResults.accounts[0].name;
        }
        else if (searchResults.accounts.length === 1) {
            return searchResults.accounts[0].name;
        }
        else if (searchResults.accounts.length > 1) {
            return "Multiple Accounts";
        }
        else {
            return undefined;
        }
    });
    const convertWebexTaskHistoryItemToCallHistoryItem = (webexTaskHistoryItem) => {
        const { attributes, originDescription, destinationDescription } = webexTaskHistoryItem;
        return {
            type: attributes.direction,
            timestamp: attributes.createdTime,
            origin: attributes.origin,
            destination: attributes.destination,
            originDescription: originDescription,
            destinationDescription: destinationDescription,
            status: attributes.status,
        };
    };
    // Memoized function to fetch customer description
    const getCachedCustomerDescription = (phoneNumber) => __awaiter(void 0, void 0, void 0, function* () {
        if (descriptionsCache.current.has(phoneNumber)) {
            return descriptionsCache.current.get(phoneNumber); // Return cached description
        }
        const description = yield getCustomerDescription(phoneNumber); // Fetch if not cached
        descriptionsCache.current.set(phoneNumber, description); // Cache the fetched value
        return description;
    });
    useEffect(() => {
        const updateCallHistoryWithCustomerDescriptions = () => __awaiter(void 0, void 0, void 0, function* () {
            const newCallHistoryItems = webex.taskHistory.map(convertWebexTaskHistoryItemToCallHistoryItem).sort((a, b) => b.timestamp - a.timestamp);
            setCallHistory(newCallHistoryItems);
            const updatedCallHistoryItems = yield Promise.all(newCallHistoryItems.map((item) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                const originDescription = (_a = item.originDescription) !== null && _a !== void 0 ? _a : (yield getCachedCustomerDescription(item.origin));
                const destinationDescription = (_b = item.destinationDescription) !== null && _b !== void 0 ? _b : (yield getCachedCustomerDescription(item.destination));
                return Object.assign(Object.assign({}, item), { originDescription, destinationDescription });
            })));
            setCallHistory(updatedCallHistoryItems);
        });
        updateCallHistoryWithCustomerDescriptions();
    }, [webex.taskHistory]);
    const searchPhoneCall = (interactionId) => __awaiter(void 0, void 0, void 0, function* () {
        const selects = [
            "subject",
            "rdo_webexid",
            "rdo_wrapupcode",
            "directioncode",
            "statuscode",
            "statecode",
            "phonenumber",
            "description",
            "actualstart",
            "actualend",
            "actualdurationminutes",
            "_regardingobjectid_value",
            "_ownerid_value",
            "_owninguser_value",
            "_owningteam_value",
            "createdon",
            "modifiedon",
        ].join(",");
        const phoneCallQuery = [`$filter=rdo_webexid eq '${interactionId}'`, `$select=${selects}`].join("&");
        return yield searchEntityToArray({ entityName: "phonecall", query: phoneCallQuery });
    });
    const searchCallerId = (callerId) => __awaiter(void 0, void 0, void 0, function* () {
        if (!Microsoft) {
            console.error("RDO: DynamicsProvidor: searchCallerId: Dynamics not initialised");
            throw new Error("Dynamics not initialised");
        }
        const encodedCallerId = encodeURIComponent(callerId);
        const accountsQuery = [
            `$filter=telephone1 eq '${encodedCallerId}' or telephone2 eq '${encodedCallerId}' or telephone3 eq '${encodedCallerId}'`,
            "$select=name,accountnumber,telephone1,telephone2,telephone3",
            "$top=10",
        ].join("&");
        const contactsQuery = [
            `$filter=telephone1 eq '${encodedCallerId}' or mobilephone eq '${encodedCallerId}'`,
            "$select=fullname,mobilephone,telephone1,jobtitle",
            "$expand=parentcustomerid_account($select=name,accountnumber,telephone1,telephone2,telephone3)",
            "$top=10",
        ].join("&");
        const callBackQuery = [
            `$filter=statecode eq 0 and (rdo_callbacknumber eq '${encodedCallerId}' or regardingobjectid_account_rdo_callbackrequest/telephone1 eq '${encodedCallerId}' or regardingobjectid_account_rdo_callbackrequest/telephone2  eq '${encodedCallerId}' or regardingobjectid_account_rdo_callbackrequest/telephone3  eq '${encodedCallerId}' or regardingobjectid_contact_rdo_callbackrequest/mobilephone eq '${encodedCallerId}' or regardingobjectid_contact_rdo_callbackrequest/telephone1 eq '${encodedCallerId}')`,
            "$select=activityid,rdo_callbacknumber,subject,scheduledend,prioritycode,rdo_customercallsecondattempt,_ownerid_value",
            "$top=5",
        ].join("&");
        const promises = [
            searchEntityToArray({ entityName: "account", query: accountsQuery }),
            searchEntityToArray({ entityName: "contact", query: contactsQuery }),
            searchEntityToArray({ entityName: "rdo_callbackrequest", query: callBackQuery }),
        ];
        const results = yield Promise.all(promises);
        const accounts = results[0].map((account) => (Object.assign(Object.assign({}, account), { contacts: [] })));
        const contacts = results[1];
        const callBacks = results[2];
        // Merge contacts into accounts
        for (const contact of contacts) {
            const account = accounts.find((a) => a.accountid === contact.parentcustomerid_account.accountid);
            if (account && Array.isArray(account.contacts)) {
                account.contacts.push(contact);
            }
            else if (account) {
                account.contacts = [contact];
            }
            else {
                accounts.push(Object.assign(Object.assign({}, contact.parentcustomerid_account), { contacts: [contact] }));
            }
        }
        return { accounts, callBacks };
    });
    const addBusinessHours = (start, hoursToAdd, weekdayHours, // e.g., { start: 9, end: 17 } for 9 AM - 5 PM
    saturdayHours // e.g., { start: 9, end: 12 } for 9 AM - 12 PM
    ) => {
        let currentDate = new Date(start);
        let remainingHours = hoursToAdd;
        function isBusinessDay(date) {
            const day = date.getDay();
            return (day >= 1 && day <= 5) || day === 6; // Monday-Saturday
        }
        function getBusinessHoursForDay(date) {
            return date.getDay() === 6 ? saturdayHours : weekdayHours;
        }
        while (remainingHours > 0) {
            const dayHours = getBusinessHoursForDay(currentDate);
            const startHour = dayHours.start;
            const endHour = dayHours.end;
            if (startHour >= endHour) {
                // Skip invalid business hours
                currentDate.setDate(currentDate.getDate() + 1);
                currentDate.setHours(startHour, 0, 0, 0);
                continue;
            }
            let currentHour = currentDate.getHours();
            // If outside business hours, move to the next valid business start time
            if (currentHour < startHour) {
                currentDate.setHours(startHour, 0, 0, 0);
                continue;
            }
            else if (currentHour >= endHour || currentDate.getDay() === 0) {
                // Move to next business day
                do {
                    currentDate.setDate(currentDate.getDate() + 1);
                } while (!isBusinessDay(currentDate));
                const nextDayHours = getBusinessHoursForDay(currentDate);
                currentDate.setHours(nextDayHours.start, 0, 0, 0);
                continue;
            }
            // Calculate available hours for the current day
            let availableHours = endHour - currentHour;
            if (remainingHours <= availableHours) {
                // If we can complete within today's working hours
                currentDate.setHours(currentHour + remainingHours);
                return currentDate;
            }
            else {
                // Consume all available hours and move to the next business day
                remainingHours -= availableHours;
                do {
                    currentDate.setDate(currentDate.getDate() + 1);
                } while (!isBusinessDay(currentDate));
                const nextDayHours = getBusinessHoursForDay(currentDate);
                currentDate.setHours(nextDayHours.start, 0, 0, 0);
            }
        }
        return currentDate;
    };
    const openNewCallBackRequest = (formParams) => __awaiter(void 0, void 0, void 0, function* () {
        const formOptions = { entityName: "rdo_callbackrequest" };
        Microsoft.CIFramework.openForm(JSON.stringify(formOptions), JSON.stringify(formParams));
    });
    const determineEnquiryTypeFromDepartment = (department) => {
        if (department.toLowerCase() === "parts") {
            return EnquiryType.Parts;
        }
        if (department.toLowerCase() === "service") {
            return EnquiryType.Service;
        }
        if (department.toLowerCase() === "sales") {
            return EnquiryType.Sales;
        }
        if (department.toLowerCase() === "accounts") {
            return EnquiryType.Accounts;
        }
        return EnquiryType.Other;
    };
    const getTeamFromBranchDepartment = (branch, department) => __awaiter(void 0, void 0, void 0, function* () {
        if (!branch || !department)
            return null;
        const searchName = `${branch} ${department}`.toLowerCase().replace(/[^a-z]/g, "");
        const team = teamsRef.current.find((team) => team.name.toLowerCase().replace(/[^a-z]/g, "") === searchName);
        return team !== null && team !== void 0 ? team : null;
    });
    const popToNewCallBack = (searchResults, callerId, branch, department) => __awaiter(void 0, void 0, void 0, function* () {
        const enquiryType = department ? determineEnquiryTypeFromDepartment(department) : EnquiryType.Other;
        const team = yield getTeamFromBranchDepartment(branch, department);
        let newFormParams = {
            rdo_enquirytype: enquiryType,
            rdo_callbacknumber: callerId,
        };
        if (team) {
            newFormParams.ownerid = team.teamid;
            newFormParams.owneridtype = "team";
            newFormParams.owneridname = team.name;
        }
        // if single matching contact, then pop new call-back-request against contact
        if (searchResults.accounts.length === 1 && searchResults.accounts[0].contacts.length === 1) {
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop new call back request against single contact:", searchResults.accounts[0].contacts[0].contactid);
            const contact = searchResults.accounts[0].contacts[0];
            newFormParams.regardingobjectid = contact.contactid;
            newFormParams.regardingobjectidname = contact.fullname;
            newFormParams.regardingobjectidtype = "contact";
            openNewCallBackRequest(newFormParams);
        }
        // else if single matching account (including multiple matching contacts but single account), then pop new call-back-request against account
        else if (searchResults.accounts.length === 1) {
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop new call back request against single account:", searchResults.accounts[0].accountid);
            const account = searchResults.accounts[0];
            newFormParams.regardingobjectid = account.accountid;
            newFormParams.regardingobjectidname = account.name;
            newFormParams.regardingobjectidtype = "account";
            openNewCallBackRequest(newFormParams);
        }
        // else pop to a new call back request page
        else {
            logger.info("DynamicsProvidor", `handleAgentOfferContact: pop new blank call back request for phone number ${callerId}`);
            openNewCallBackRequest(newFormParams);
        }
    });
    const popToRecord = (searchResults, callerId) => __awaiter(void 0, void 0, void 0, function* () {
        // if single matching contact, then pop contact
        if (searchResults.accounts.length === 1 && searchResults.accounts[0].contacts.length === 1) {
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop single contact:", searchResults.accounts[0].contacts[0].contactid);
            Microsoft.CIFramework.searchAndOpenRecords("contact", `$filter=contactid eq '${searchResults.accounts[0].contacts[0].contactid}'`, false);
        }
        // else if single matching account (including multiple matching contacts but single account), then pop account
        else if (searchResults.accounts.length === 1) {
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop single account:", searchResults.accounts[0].accountid);
            Microsoft.CIFramework.searchAndOpenRecords("account", `$filter=accountid eq '${searchResults.accounts[0].accountid}'`, false);
        }
        // else if multiple matching accounts, then show search page.
        else if (searchResults.accounts.length > 1) {
            logger.info("DynamicsProvidor", `handleAgentOfferContact: multiple mactching accounts for phone number ${callerId}, pop search page.`);
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "search", searchText: callerId, searchType: SearchType.Relevance });
        }
        // else if no matching records, then... show search page???
        else {
            logger.info("DynamicsProvidor", `handleAgentOfferContact: no matching records for phone number ${callerId}, pop search page.`);
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "search", searchText: callerId, searchType: SearchType.Relevance });
        }
    });
    const popFromSearchResults = (searchResults, callerId, branch, department) => __awaiter(void 0, void 0, void 0, function* () {
        // if single matching call-back-request, then pop that
        if (searchResults.callBacks.length === 1) {
            const callBack = searchResults.callBacks[0];
            const callBackId = callBack.activityid;
            logger.info("DynamicsProvidor", "handleAgentOfferContact: pop single call-back-request:", callBackId);
            Microsoft.CIFramework.searchAndOpenRecords("rdo_callbackrequest", `$filter=activityid eq '${callBackId}'`, false);
        }
        // else if multiple matching call-back-requests, then show search page.
        else if (searchResults.callBacks.length > 1) {
            logger.info("DynamicsProvidor", `handleAgentOfferContact: multiple mactching call-back-requests for phone number ${callerId}, pop search page.`);
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "search", searchText: callerId, searchType: SearchType.Relevance });
        }
        // else if config is set to pop to a new callback request, then pop new callback request
        else if (screenPopToRef.current === EnumScreenPopTo.NEW_CALLBACK) {
            yield popToNewCallBack(searchResults, callerId, branch, department);
        }
        // else pop into the appropriate account/contact record
        else {
            yield popToRecord(searchResults, callerId);
        }
    });
    const findAndOpenRecords = (_a) => __awaiter(void 0, [_a], void 0, function* ({ interactionId, callerId, directionCode, doScreenPop, branch, department }) {
        var _b;
        let searchResults = { accounts: [], callBacks: [] };
        // First, clear the current matching records...
        updateActiveRecords({
            interactionId: interactionId,
            callerId: callerId,
            accounts: { loading: true, error: null, data: undefined },
            callBacks: { loading: true, error: null, data: undefined },
            directionCode,
        });
        try {
            searchResults = yield searchCallerId(callerId);
            logger.info("DynamicsProvidor", `findAndOpenRecords: searchResults for '${callerId}':`, searchResults);
            searchResultRef.current = {
                interactionId: interactionId,
                callerId: callerId,
                searchResults,
            };
            updateActiveRecords({
                interactionId: interactionId,
                callerId: callerId,
                accounts: { loading: false, error: null, data: searchResults.accounts },
                callBacks: { loading: false, error: null, data: searchResults.callBacks },
                directionCode,
            });
        }
        catch (error) {
            const forcedError = error !== null && error !== void 0 ? error : new Error("Failed to fetch records");
            updateActiveRecords({
                interactionId: interactionId,
                callerId: callerId,
                accounts: { loading: false, error: forcedError, data: undefined },
                callBacks: { loading: false, error: forcedError, data: undefined },
                directionCode,
            });
        }
        try {
            if (!((_b = activeRecordsRef.current) === null || _b === void 0 ? void 0 : _b.phoneCall)) {
                updateActiveRecords({ interactionId: interactionId, phoneCall: { data: undefined, loading: true, error: null } });
                const phoneCalls = yield searchPhoneCall(interactionId);
                if (phoneCalls.length > 0) {
                    updateActiveRecords({ interactionId: interactionId, phoneCall: { data: phoneCalls[0], loading: false, error: null } });
                }
                else {
                    updateActiveRecords({ interactionId: interactionId, phoneCall: { data: null, loading: false, error: null } });
                }
            }
        }
        catch (error) {
            const forcedError = error !== null && error !== void 0 ? error : new Error("Failed to fetch phone call");
            updateActiveRecords({ interactionId: interactionId, phoneCall: { data: undefined, loading: false, error: forcedError } });
        }
        if (doScreenPop) {
            yield popFromSearchResults(searchResults, callerId, branch, department);
        }
    });
    const updateActiveRecords = (updates) => {
        var _a;
        // If update is undefined, then clear active records
        if (updates === undefined) {
            logger.info("DynamicsProvidor", "updateActiveRecords: clearing active records");
            activeRecordsRef.current = undefined;
            setMatchingRecords(undefined);
            // if update has a new interactionId, then replace the active records with the update
        }
        else if (((_a = activeRecordsRef.current) === null || _a === void 0 ? void 0 : _a.interactionId) !== (updates === null || updates === void 0 ? void 0 : updates.interactionId)) {
            logger.info("DynamicsProvidor", "updateActiveRecords: replacing active records with new updates");
            activeRecordsRef.current = updates;
            setMatchingRecords(updates);
            // else, merge the updates into the active records
        }
        else {
            logger.info("DynamicsProvidor", "updateActiveRecords: merging updates into active records");
            activeRecordsRef.current = Object.assign(Object.assign({}, activeRecordsRef.current), updates);
            setMatchingRecords(activeRecordsRef.current);
        }
    };
    const updatePhoneCallActivity = (activityId, payload) => __awaiter(void 0, void 0, void 0, function* () {
        if (Object.keys(payload).length === 0) {
            logger.info("DynamicsProvidor", "updatePhoneCallActivity: no payload to update");
            return;
        }
        logger.info("DynamicsProvidor", "updatePhoneCallActivity: updating phone call activity:", payload);
        yield Microsoft.CIFramework.updateRecord("phonecall", activityId, JSON.stringify(payload));
    });
    const endPhoneCallActivity = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
        yield updatePhoneCallActivity(activityId, { actualend: new Date().toISOString() });
    });
    const closePhoneCallActivity = (activityId, wrapupCode) => __awaiter(void 0, void 0, void 0, function* () {
        const phoneCall = yield fetchPhoneCallById(activityId);
        let update = { statecode: 1, statuscode: 2, rdo_wrapupcode: wrapupCode };
        if ((phoneCall === null || phoneCall === void 0 ? void 0 : phoneCall.actualstart) && (phoneCall === null || phoneCall === void 0 ? void 0 : phoneCall.actualend)) {
            const start = new Date(phoneCall.actualstart);
            const end = new Date(phoneCall.actualend);
            const durationMins = Math.floor((end.getTime() - start.getTime()) / 60000);
            update.actualdurationminutes = durationMins;
        }
        yield updatePhoneCallActivity(activityId, update);
    });
    const fetchPhoneCallById = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const phoneCallReponse = yield Microsoft.CIFramework.retrieveRecord("phonecall", activityId, `?$select=${phoneCallSelects.join(",")}`);
            return JSON.parse(phoneCallReponse);
        }
        catch (error) {
            logger.error("DynamicsProvidor", "fetchPhoneCallById: Failed to fetch phone call by id. Error:", error);
            throw error;
        }
    });
    const doCreatePhoneCallActivity = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const createPhoneCallResponse = yield Microsoft.CIFramework.createRecord("phonecall", JSON.stringify(payload));
        logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: createPhoneCallResponse:", createPhoneCallResponse);
        const phoneCallResponseObject = JSON.parse(createPhoneCallResponse);
        logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: phoneCallResponseObject:", phoneCallResponseObject);
        if (phoneCallResponseObject.id) {
            logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: phone call created with id:", phoneCallResponseObject.id);
            const phoneCall = yield fetchPhoneCallById(phoneCallResponseObject.id);
            logger.debug("DynamicsProvidor", "doCreatePhoneCallActivity: phone call fetched:", phoneCall);
            return phoneCall;
        }
        else {
            logger.error("DynamicsProvidor", "Failed to create phone call activity. Unexpected response from Dynamics:", createPhoneCallResponse);
            throw new Error("Failed to create phone call activity. Unexpected response from Dynamics");
        }
    });
    const createPhoneCallActivity = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        logger.info("DynamicsProvidor", "createPhoneCallActivity: creating phone call activity:", payload);
        const existingPhoneCall = yield searchPhoneCall(payload.rdo_webexid);
        if (existingPhoneCall.length > 0) {
            const errMessage = `createPhoneCallActivity: phone call id='${existingPhoneCall[0].activityid}' already exists` +
                ` for rdo_webexid '${payload.rdo_webexid}'. Code should update, not create a new activity!`;
            logger.error("DynamicsProvidor", errMessage);
            throw new Error(errMessage);
        }
        try {
            const phoneCall = yield doCreatePhoneCallActivity(payload);
            logger.info("DynamicsProvidor", "createPhoneCallActivity: createPhoneCallResponse:", phoneCall);
            return phoneCall;
        }
        catch (error) {
            logger.error("DynamicsProvidor", "createPhoneCallActivity: error:", error);
            throw error;
        }
    });
    const phoneCallBaseBuilder = (routeEventData) => {
        var _a, _b, _c, _d;
        const direction = routeEventData.interaction.contactDirection.type;
        if (direction === CONTACT_DIRECTION.INBOUND) {
            const vars = routeEventData.interaction.callAssociatedData;
            const callTo = ((_a = vars === null || vars === void 0 ? void 0 : vars.Branch) === null || _a === void 0 ? void 0 : _a.value) && ((_b = vars === null || vars === void 0 ? void 0 : vars.Department) === null || _b === void 0 ? void 0 : _b.value)
                ? `${vars.Branch.value} ${vars.Department.value}`
                : (_d = (_c = vars === null || vars === void 0 ? void 0 : vars.CallTo) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : routeEventData.interaction.callAssociatedDetails.ani;
            return {
                rdo_webexid: routeEventData.interaction.interactionId,
                phonenumber: routeEventData.interaction.callAssociatedDetails.ani,
                directioncode: CallDirection.Incoming,
                subject: `Called ${callTo}, answered by ${userDisplayNameRef.current}`,
                actualstart: new Date().toISOString(),
            };
        }
        else {
            return {
                rdo_webexid: routeEventData.interaction.interactionId,
                phonenumber: routeEventData.interaction.callAssociatedDetails.dn,
                directioncode: CallDirection.Outgoing,
                subject: ` ${userDisplayNameRef.current} called ${routeEventData.interaction.callAssociatedDetails.dn}`,
                actualstart: new Date().toISOString(),
            };
        }
    };
    const createPhoneCallFromSearchResults = (searchResults, routeEventData) => __awaiter(void 0, void 0, void 0, function* () {
        const activityBase = phoneCallBaseBuilder(routeEventData);
        // if single matching contact, then create phone call against contact
        if (searchResults.accounts.length === 1 && searchResults.accounts[0].contacts.length === 1) {
            const contact = searchResults.accounts[0].contacts[0];
            logger.info("DynamicsProvidor", "createInboundPhoneCallFromSearchResults: create phone call against contact:", contact.contactid);
            return yield createPhoneCallActivity(Object.assign(Object.assign({}, activityBase), { phonecall_activity_parties: [
                    { participationtypemask: ParticipationTypeMask.From, "partyid_contact@odata.bind": `/contacts(${contact.contactid})` },
                    { participationtypemask: ParticipationTypeMask.To, "partyid_systemuser@odata.bind": `/systemusers(${userIdRef.current})` },
                ], "regardingobjectid_contact@odata.bind": `/contacts(${contact.contactid})` }));
            // if single matching account (or multiple contacts from one account), then create phone call against account
        }
        else if (searchResults.accounts.length === 1) {
            const account = searchResults.accounts[0];
            logger.info("DynamicsProvidor", "createInboundPhoneCallFromSearchResults: create phone call against account:", account.accountid);
            return yield createPhoneCallActivity(Object.assign(Object.assign({}, activityBase), { phonecall_activity_parties: [
                    { participationtypemask: ParticipationTypeMask.From, "partyid_account@odata.bind": `/accounts(${account.accountid})` },
                    { participationtypemask: ParticipationTypeMask.To, "partyid_systemuser@odata.bind": `/systemusers(${userIdRef.current})` },
                ], "regardingobjectid_account@odata.bind": `/accounts(${account.accountid})` }));
        }
        else {
            logger.info("DynamicsProvidor", "handleTaskAccepted: no matching records to create phone call against.");
        }
    });
    const createPhoneCallOnHandleAnswer = (routeEventData) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const interaction = routeEventData.interaction;
        const interactionId = interaction.interactionId;
        const direction = interaction.contactDirection.type;
        const callerId = direction === CONTACT_DIRECTION.INBOUND ? interaction.callAssociatedDetails.ani : interaction.callAssociatedDetails.dn;
        try {
            const callerIdSearchResults = ((_a = searchResultRef.current) === null || _a === void 0 ? void 0 : _a.callerId) === callerId ? searchResultRef.current.searchResults : yield searchCallerId(callerId);
            const phoneCall = yield createPhoneCallFromSearchResults(callerIdSearchResults, routeEventData);
            setTimeout(() => {
                if (phoneCall) {
                    updateActiveRecords({ interactionId, phoneCall: { data: phoneCall, loading: false, error: null } });
                    if (screenPopToRef.current !== EnumScreenPopTo.NEW_CALLBACK) {
                        Xrm === null || Xrm === void 0 ? void 0 : Xrm.Page.ui.refresh();
                    }
                }
                updateActiveRecords({ interactionId, phoneCall: { data: phoneCall, loading: false, error: null } });
            }, 1000);
        }
        catch (error) {
            logger.error("DynamicsProvidor", "createInboundPhoneCallOnHandleAnswer: error:", error);
            updateActiveRecords({ interactionId, phoneCall: { data: undefined, loading: false, error } });
            Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({ message: "Failed to create phone call activity", details: JSON.stringify(error) });
        }
    });
    const openRecord = useCallback((entityType, entityId) => __awaiter(void 0, void 0, void 0, function* () {
        yield (Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.navigateTo({ pageType: "entityrecord", entityName: entityType, entityId: entityId }));
    }), [Microsoft]);
    useEffect(() => {
        const parseRawCustomParams = (rawCustomParams) => {
            let result = {};
            try {
                const parsed = JSON.parse(rawCustomParams);
                logger.debug("DynamicsProvidor", "parseRawCustomParams: parsed custom params:", parsed);
                if (typeof parsed !== "object") {
                    return result;
                }
                if (parsed.hasOwnProperty("width")) {
                    result.width = parseInt(parsed.width);
                }
                if (parsed.hasOwnProperty("screenPopTo") &&
                    typeof parsed.screenPopTo === "string" &&
                    Object.values(EnumScreenPopTo).includes(parsed.screenPopTo)) {
                    result.screenPopTo = parsed.screenPopTo;
                }
                if (parsed.hasOwnProperty("featuresEnabled") &&
                    Array.isArray(parsed.featuresEnabled) &&
                    parsed.featuresEnabled.every((f) => typeof f === "string")) {
                    result.featuresEnabled = parsed.featuresEnabled;
                }
                else if (parsed.hasOwnProperty("featuresEnabled")) {
                    logger.error("DynamicsProvidor", "parseRawCustomParams: featuresEnabled is not an array of strings:", parsed.featuresEnabled);
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "parseRawCustomParams: error parsing custom params:", error);
                window.alert("Error parsing custom params for RDO Webex CIFv1. Please contact your system administrator." + `\n\nCustom Params: \n${rawCustomParams}`);
            }
            finally {
                return result;
            }
        };
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                logger.info("DynamicsProvidor", "initialising...");
                const { ms, xrm } = yield initDynamics();
                logger.info("DynamicsProvidor", "initDynamics completed...");
                setIsInitialising(false);
                setMicrosoft(ms);
                setXrm(xrm);
                // @ts-ignore
                window.RdoMs = ms;
                // @ts-ignore
                window.RdoXrm = xrm;
                setIsReady(true);
                const env = JSON.parse(yield ms.CIFramework.getEnvironment());
                logger.info;
                userIdRef.current = (env === null || env === void 0 ? void 0 : env.userId) ? env === null || env === void 0 ? void 0 : env.userId.toLowerCase().replace(/[{}]/g, "") : undefined;
                if (!userIdRef.current) {
                    logger.error("DynamicsProvidor", "User Id not found in environment!!");
                }
                userDisplayNameRef.current = env === null || env === void 0 ? void 0 : env.username;
                const parsedParams = parseRawCustomParams(env === null || env === void 0 ? void 0 : env.customParams);
                logger.info("DynamicsProvidor", "initialised with env:", env);
                setMsEnv(env);
                setCustomParams(parsedParams);
                if (!(parsedParams === null || parsedParams === void 0 ? void 0 : parsedParams.width)) {
                    logger.error("DynamicsProvidor", "Custom Params: width is not set!");
                }
                else {
                    ms.CIFramework.setWidth(parsedParams.width);
                }
                if (parsedParams === null || parsedParams === void 0 ? void 0 : parsedParams.screenPopTo) {
                    screenPopToRef.current = parsedParams.screenPopTo;
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "initialising error:", error);
                setIsInitialising(false);
                setIsError(true);
            }
        });
        init();
    }, []);
    // Register CIF event handlers:
    useEffect(() => {
        if (!Microsoft || !webex || isInitialising)
            return;
        const handleClickToAct = (eventMessage) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            const event = JSON.parse(eventMessage);
            logger.info("DynamicsProvidor", "Received ClickToAct Event:", event);
            const phoneNumber = (_b = (_a = event.value) === null || _a === void 0 ? void 0 : _a.replace(/[^+0-9]/g, "")) !== null && _b !== void 0 ? _b : undefined;
            if (phoneNumber && ((_c = phoneNumber.match(/^\+?[0-9]{6,12}$/)) === null || _c === void 0 ? void 0 : _c.length)) {
                logger.info("DynamicsProvidor", `Making phone call to: ${phoneNumber}`);
                if (((_d = webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.WRAP_UP) {
                    Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({
                        message: `Cannot make call while in wrap-up`,
                        details: `You are currently in wrap-up state. You cannot make or receive calls in wrap-up state.`,
                    });
                    return;
                }
                try {
                    yield webex.makeCall(phoneNumber);
                }
                catch (error) {
                    logger.error("DynamicsProvidor", "Failed to make call. Error:", error);
                    Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({ message: `Failed to make call`, details: JSON.stringify(error) });
                }
            }
            else {
                logger.error("DynamicsProvidor", "Invalid phone number:", phoneNumber);
                Xrm === null || Xrm === void 0 ? void 0 : Xrm.Navigation.openErrorDialog({ message: `Invalid phone number`, details: `Phone number: ${phoneNumber} is not valid.` });
            }
        });
        // Register the handlers:
        Microsoft.CIFramework.addHandler(CIF_EVENT.OnClickToAct, handleClickToAct);
        // Unregister the handlers on unmount;
        return () => {
            Microsoft.CIFramework.removeHandler(CIF_EVENT.OnClickToAct, handleClickToAct);
        };
    }, [Microsoft, webex, isInitialising]);
    // Register Webex Event Handlers:
    useEffect(() => {
        if (!Microsoft || !Xrm || isInitialising)
            return;
        logger.log("DynamicsProvidor", "useEffect: Registering routing event handlers...");
        // Handle events: AgentOfferContact, AgentContact - Search for callerId and pop matching records
        const handleAgentOfferContact = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f;
            try {
                const data = event.data;
                logger.info("DynamicsProvidor", "handleAgentOfferContact: event:", event);
                const direction = data.interaction.contactDirection.type;
                const branch = (_c = (_b = (_a = data.interaction.callAssociatedData) === null || _a === void 0 ? void 0 : _a.Branch) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : undefined;
                const department = (_f = (_e = (_d = data.interaction.callAssociatedData) === null || _d === void 0 ? void 0 : _d.Department) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : undefined;
                if (direction === CONTACT_DIRECTION.INBOUND) {
                    const callerId = data.interaction.callAssociatedDetails.ani;
                    yield findAndOpenRecords({
                        interactionId: data.interaction.interactionId,
                        callerId,
                        directionCode: CallDirection.Incoming,
                        doScreenPop: true,
                        branch,
                        department,
                    });
                }
                else {
                    const callerId = data.interaction.callAssociatedDetails.dn;
                    yield findAndOpenRecords({ interactionId: data.interaction.interactionId, callerId, directionCode: CallDirection.Outgoing });
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "handleAgentOfferContact: error:", error);
            }
        });
        // Handle event: AgentContactAssigned - Create phone call activity
        const handleTaskAccepted = (event) => __awaiter(void 0, void 0, void 0, function* () {
            const data = event.data;
            logger.info("DynamicsProvidor", "handleTaskAccepted: event:", event);
            yield createPhoneCallOnHandleAnswer(data);
        });
        // handle event ContactEnded - Update phone call activity to set actual end time
        const handleContactEnded = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            try {
                const phoneCall = (_b = (_a = activeRecordsRef.current) === null || _a === void 0 ? void 0 : _a.phoneCall) === null || _b === void 0 ? void 0 : _b.data;
                if (phoneCall) {
                    logger.debug("DynamicsProvidor", "handleContactEnded: Have phone call to end, setting actual end time...");
                    yield endPhoneCallActivity(phoneCall.activityid);
                }
                else {
                    logger.debug("DynamicsProvidor", "handleContactEnded: No phone call to end");
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "handleContactEnded: failed to end phone call. Error:", error);
            }
        });
        // Handle event: AgentWrappedUp - Close phone call activity then clear the active records
        const handleWrappedUp = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            try {
                const data = event.data;
                const phoneCall = (_b = (_a = activeRecordsRef.current) === null || _a === void 0 ? void 0 : _a.phoneCall) === null || _b === void 0 ? void 0 : _b.data;
                if (phoneCall) {
                    const auxCode = data.wrapUpAuxCodeId ? webex.getWrapUpCodeById(data.wrapUpAuxCodeId) : undefined;
                    const codeToSave = auxCode ? auxCode.name : (_c = data.wrapUpAuxCodeId) !== null && _c !== void 0 ? _c : "No Aux Code";
                    logger.debug("DynamicsProvidor", `handleWrappedUp: Have phone call to wrap up, closing phone call activity with code: ${codeToSave}`);
                    yield closePhoneCallActivity(phoneCall.activityid, codeToSave);
                    if (screenPopToRef.current !== EnumScreenPopTo.NEW_CALLBACK) {
                        Xrm.Page.ui.refresh();
                    }
                }
                else {
                    logger.debug("DynamicsProvidor", "handleWrappedUp: No phone call to wrap up");
                }
            }
            catch (error) {
                logger.error("DynamicsProvidor", "handleWrappedUp: failed to wrap up and end phone call. Error:", error);
            }
            finally {
                logger.info("DynamicsProvidor", "handleWrappedUp: Clearing active records");
                activeRecordsRef.current = undefined;
                setMatchingRecords(undefined);
            }
        });
        webex.registerEventListener(ROUTING_TYPE.AgentOfferContact, handleAgentOfferContact);
        webex.registerEventListener(ROUTING_TYPE.AgentContact, handleAgentOfferContact);
        webex.registerEventListener(ROUTING_TYPE.AgentContactAssigned, handleTaskAccepted);
        webex.registerEventListener(ROUTING_TYPE.ContactEnded, handleContactEnded);
        webex.registerEventListener(ROUTING_TYPE.AgentWrappedUp, handleWrappedUp);
        return () => {
            webex.unregisterEventListener(ROUTING_TYPE.AgentOfferContact, handleAgentOfferContact);
            webex.unregisterEventListener(ROUTING_TYPE.AgentContact, handleAgentOfferContact);
            webex.unregisterEventListener(ROUTING_TYPE.AgentContactAssigned, handleTaskAccepted);
            webex.registerEventListener(ROUTING_TYPE.ContactEnded, handleContactEnded);
            webex.registerEventListener(ROUTING_TYPE.AgentWrappedUp, handleWrappedUp);
        };
    }, [Microsoft, Xrm, isInitialising]);
    useEffect(() => {
        if (!Microsoft || isInitialising) {
            return;
        }
        const fetchTeams = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const teams = yield searchEntityToArray({
                    entityName: "team",
                    query: "$select=teamid,name&$filter=rdo_departmenttype ne null",
                    timeoutMs: 15000,
                });
                logger.info("DynamicsProvidor", "fetchTeams: fetched teams:", teams);
                teamsRef.current = teams;
            }
            catch (error) {
                logger.error("DynamicsProvidor", "fetchTeams: error:", error);
            }
        });
        fetchTeams();
        setInterval(fetchTeams, 15 * 60 * 1000); // Fetch teams every 15 minutes
    }, [Microsoft, isInitialising]);
    return (React.createElement(DynamicsContext.Provider, { value: { isInitialising, isError, isReady, matchingRecords, openRecord, callHistory, customParams } }, children));
};
export const useDynamics = () => {
    const context = useContext(DynamicsContext);
    if (!context) {
        logger.error("DynamicsProvidor", "useDynamics must be used within a DynamicsProvider");
    }
    return context;
};
