export var EnquiryType;
(function (EnquiryType) {
    EnquiryType[EnquiryType["Parts"] = 1] = "Parts";
    EnquiryType[EnquiryType["Service"] = 2] = "Service";
    EnquiryType[EnquiryType["Sales"] = 3] = "Sales";
    EnquiryType[EnquiryType["Accounts"] = 4] = "Accounts";
    EnquiryType[EnquiryType["Other"] = 9] = "Other";
})(EnquiryType || (EnquiryType = {}));
export var PriorityCode;
(function (PriorityCode) {
    PriorityCode[PriorityCode["Normal"] = 0] = "Normal";
    PriorityCode[PriorityCode["Urgent"] = 1] = "Urgent";
})(PriorityCode || (PriorityCode = {}));
