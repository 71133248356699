export var EntityType;
(function (EntityType) {
    EntityType["account"] = "account";
    EntityType["contact"] = "contact";
    EntityType["rdo_callbackrequest"] = "rdo_callbackrequest";
    EntityType["phonecall"] = "phonecall";
})(EntityType || (EntityType = {}));
export var EnumScreenPopTo;
(function (EnumScreenPopTo) {
    EnumScreenPopTo["MATCHING_RECORD"] = "MATCHING_RECORD";
    EnumScreenPopTo["NEW_CALLBACK"] = "NEW_CALLBACK";
})(EnumScreenPopTo || (EnumScreenPopTo = {}));
