import React from "react";
import { TabList, Tab, makeStyles } from "@fluentui/react-components";
import { DialpadRegular, BookmarkSearchRegular } from "@fluentui/react-icons";
import { SpeedTransfers } from "./SpeedTransfers/SpeedTransfers";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { Records } from "./Records/Records";
import { ScrollBarStyle } from "../../utils/CustomTokens";
import { CALL_STATE } from "../../services/webex/contactcenter/models/Enums";
import { useDynamics } from "../../services/dynamics/DynamicsProvidor";
const styles = makeStyles({
    root: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        overflow: "hidden",
        marginTop: "10px",
    },
    tabListContainer: {
        display: "flex",
        justifyContent: "center",
        flexShrink: 0,
        width: "100%",
    },
    actionContainer: Object.assign({ flexGrow: 1, overflowY: "auto", width: "100%", boxSizing: "border-box", paddingBottom: "40px" }, ScrollBarStyle),
});
export const ActionsHub = () => {
    const classes = styles();
    const [currentTab, setCurrentTab] = React.useState("Records");
    const webex = useWebexContactCentre();
    const dynamics = useDynamics();
    const showSpeedDials = () => {
        var _a, _b, _c, _d;
        if ((_a = dynamics.customParams.featuresEnabled) === null || _a === void 0 ? void 0 : _a.includes("SPEED_DIALS")) {
            return (((_b = webex === null || webex === void 0 ? void 0 : webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.CONNECTED || ((_c = webex === null || webex === void 0 ? void 0 : webex.task) === null || _c === void 0 ? void 0 : _c.callState) === CALL_STATE.HELD || ((_d = webex === null || webex === void 0 ? void 0 : webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.INBOUND_RINGING);
        }
        else {
            return false;
        }
    };
    const showRecords = () => {
        var _a, _b, _c, _d, _e, _f, _g;
        return (((_a = webex === null || webex === void 0 ? void 0 : webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.CONNECTED ||
            ((_b = webex === null || webex === void 0 ? void 0 : webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.HELD ||
            ((_c = webex === null || webex === void 0 ? void 0 : webex.task) === null || _c === void 0 ? void 0 : _c.callState) === CALL_STATE.INBOUND_RINGING ||
            ((_d = webex === null || webex === void 0 ? void 0 : webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.CONFERENCE ||
            ((_e = webex === null || webex === void 0 ? void 0 : webex.task) === null || _e === void 0 ? void 0 : _e.callState) === CALL_STATE.CONSULT ||
            ((_f = webex === null || webex === void 0 ? void 0 : webex.task) === null || _f === void 0 ? void 0 : _f.callState) === CALL_STATE.WRAP_UP ||
            ((_g = webex === null || webex === void 0 ? void 0 : webex.task) === null || _g === void 0 ? void 0 : _g.callState) === CALL_STATE.OUTBOUND_RINGING);
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.tabListContainer },
            React.createElement(TabList, { selectedValue: currentTab, onTabSelect: (e, data) => setCurrentTab(data.value), size: "small" },
                showRecords() && (React.createElement(Tab, { value: "Records", icon: React.createElement(BookmarkSearchRegular, null) }, "Dynamics Records")),
                showSpeedDials() && (React.createElement(Tab, { value: "SpeedDials", icon: React.createElement(DialpadRegular, null) }, "Speed Dials")))),
        React.createElement("div", { style: { height: "20px" } }),
        React.createElement("div", { className: classes.actionContainer },
            currentTab === "SpeedDials" && showSpeedDials() && React.createElement(SpeedTransfers, null),
            currentTab === "Records" && React.createElement(Records, null))));
};
