import { CheckmarkCircleFilled, CopyRegular } from "@fluentui/react-icons";
import { makeStyles } from "@fluentui/react-components";
import logger from "../utils/Logger";
import React from "react";
const useStyles = makeStyles({
    copyIcon: {
        cursor: "pointer",
        marginLeft: "10px",
    },
    copyIconClicked: {
        color: "green",
        marginLeft: "10px",
    },
});
export const CopyToClipboard = (props) => {
    const [isCopied, setIsCopied] = React.useState(false);
    const classes = useStyles();
    const handleCopyToClipboard = (text, onSuccess) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
            logger.log("ActiveCallDetails", `Copied to clipboard: ${text}`);
            onSuccess();
        })
            .catch((error) => {
            logger.error("ActiveCallDetails", "Failed to copy to clipboard", error);
        });
    };
    const handleCopy = () => {
        handleCopyToClipboard(props.text, () => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        });
    };
    if (isCopied) {
        return React.createElement(CheckmarkCircleFilled, { className: classes.copyIconClicked, style: props.styles });
    }
    return React.createElement(CopyRegular, { className: classes.copyIcon, onClick: handleCopy, style: props.styles });
};
