import React from "react";
import { Button, makeStyles, Tooltip, tokens } from "@fluentui/react-components";
import { CallForwardRegular, CallPauseRegular, CallEndRegular, CallTransferRegular, PauseOffRegular, CallTransferFilled, CallForwardFilled, CallCheckmarkRegular, CallDismissRegular, PeopleCallRegular, PlayRegular, PauseRegular, } from "@fluentui/react-icons";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { CALL_STATE, TRANSFER_RECIPIENT_TYPE } from "../../services/webex/contactcenter/models/Enums";
import { SELECTED_CALL_CONTROL } from "../../pages/OnCallPage";
import { CONTACT_DIRECTION, INTERACTION_STATE, MEDIA_MTYPE } from "../../services/webex/contactcenter/models/IRoutingMessage";
const styles = makeStyles({
    root: {
        display: "flex",
        gap: "0.5rem",
        padding: "10px",
    },
    buttons: {},
    red: {
        color: tokens.colorPaletteRedBackground3,
        ":hover": {
            color: "rgb(255, 0, 0)",
        },
    },
    activeButton: {
        color: tokens.colorBrandForeground1,
    },
    inputBox: {
        width: "100%",
    },
    popoverSurface: {
        backgroundColor: tokens.colorBrandForeground1,
    },
});
export const CallControls = (props) => {
    var _a, _b;
    const webex = useWebexContactCentre();
    const classes = styles();
    const endCall = () => {
        webex === null || webex === void 0 ? void 0 : webex.endCall(webex.task.interaction.interactionId);
    };
    const onHoldButtonToolTipText = () => {
        var _a;
        if (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.HELD) {
            return "Resume Call";
        }
        else {
            return "Hold Call";
        }
    };
    const onHoldButtonIcon = () => {
        var _a;
        if (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.HELD) {
            return React.createElement(PauseOffRegular, { className: classes.red });
        }
        else {
            return React.createElement(CallPauseRegular, null);
        }
    };
    const toggleHold = () => {
        var _a;
        const interactionId = (_a = webex.task.interaction) === null || _a === void 0 ? void 0 : _a.interactionId;
        // handle consult call holds
        if (webex.task.activeMedias.length === 1 && webex.task.activeMedias[0].mType === MEDIA_MTYPE.CONSULT) {
            webex === null || webex === void 0 ? void 0 : webex.resumeCall(interactionId, webex.task.activeMedias[0].mediaResourceId);
        }
        else if (webex.task.activeMedias.length === 1 && webex.task.activeMedias[0].mType === MEDIA_MTYPE.MAINCALL && webex.task.activeMedias[0].isHold) {
            webex === null || webex === void 0 ? void 0 : webex.resumeCall(interactionId, webex.task.activeMedias[0].mediaResourceId);
        }
        else if (webex.task.activeMedias.length === 1) {
            webex === null || webex === void 0 ? void 0 : webex.holdCall(interactionId, webex.task.activeMedias[0].mediaResourceId);
        }
        else {
            webex === null || webex === void 0 ? void 0 : webex.holdCall(interactionId, interactionId);
        }
    };
    const toggleConsultCallHold = () => {
        var _a, _b, _c;
        const interactionId = (_a = webex.task.interaction) === null || _a === void 0 ? void 0 : _a.interactionId;
        if (webex.task.activeMedias.length === 1 && webex.task.activeMedias[0].mType === MEDIA_MTYPE.CONSULT) {
            const mediaId = (_c = (_b = Object.values(webex.task.interaction.media).find((media) => media.mType === MEDIA_MTYPE.MAINCALL)) === null || _b === void 0 ? void 0 : _b.mediaResourceId) !== null && _c !== void 0 ? _c : interactionId;
            webex === null || webex === void 0 ? void 0 : webex.resumeCall(interactionId, mediaId);
        }
        else {
            toggleHold();
        }
    };
    const conferenceIsHold = () => {
        var _a, _b;
        if (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.activeMedias) && webex.task.activeMedias.length > 0 && ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.heldMedias) && webex.task.heldMedias.length === 0) {
            return false;
        }
        else {
            return true;
        }
    };
    const conferenceCallHoldTooltipText = () => {
        return conferenceIsHold() ? "Resume Conference Call" : "Place Customer on Hold";
    };
    const completeTransfer = () => {
        const interactionId = webex.task.interaction.interactionId;
        const consultMedia = Object.values(webex.task.interaction.media).find((media) => media.mType === MEDIA_MTYPE.CONSULT);
        if (!consultMedia) {
            // handle error
            return;
        }
        const agentId = webex.task.agentState.id;
        const consultParticipant = consultMedia.participants.find((participant) => participant !== agentId);
        if (!consultParticipant) {
            // handle error
            return;
        }
        webex.consultTransferCall(interactionId, consultParticipant, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
    };
    const endConsultCall = () => {
        const interactionId = webex.task.interaction.interactionId;
        webex.consultEndCall(interactionId);
    };
    const mergeCalls = () => {
        const interactionId = webex.task.interaction.interactionId;
        const consultMedia = Object.values(webex.task.interaction.media).find((media) => media.mType === MEDIA_MTYPE.CONSULT);
        if (!consultMedia) {
            // handle error
            return;
        }
        const agentId = webex.task.agentState.id;
        const consultParticipant = consultMedia.participants.find((participant) => participant !== agentId);
        if (!consultParticipant) {
            // handle error
            return;
        }
        webex.consultConferenceCall(interactionId, consultParticipant, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
    };
    const callControlsAvailable = () => {
        var _a, _b, _c, _d;
        return (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.CONNECTED ||
            ((_b = webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.HELD ||
            ((_c = webex.task) === null || _c === void 0 ? void 0 : _c.callState) === CALL_STATE.CONFERENCE ||
            ((_d = webex.task) === null || _d === void 0 ? void 0 : _d.callState) === CALL_STATE.CONSULT);
    };
    const cancelCallControlAvailable = () => {
        var _a, _b, _c, _d, _e;
        return (((_c = (_b = (_a = webex.task) === null || _a === void 0 ? void 0 : _a.interaction) === null || _b === void 0 ? void 0 : _b.contactDirection) === null || _c === void 0 ? void 0 : _c.type) === CONTACT_DIRECTION.OUTBOUND &&
            ((_e = (_d = webex.task) === null || _d === void 0 ? void 0 : _d.interaction) === null || _e === void 0 ? void 0 : _e.state) === INTERACTION_STATE.NEW);
    };
    const forwardIcon = props.selectedControl === SELECTED_CALL_CONTROL.FORWARD ? React.createElement(CallForwardFilled, { className: classes.activeButton }) : React.createElement(CallForwardRegular, null);
    const transferIcon = props.selectedControl === SELECTED_CALL_CONTROL.CONSULT ? React.createElement(CallTransferFilled, { className: classes.activeButton }) : React.createElement(CallTransferRegular, null);
    if (((_a = webex.task) === null || _a === void 0 ? void 0 : _a.callState) === CALL_STATE.CONSULT) {
        return (React.createElement("div", { className: classes.root },
            React.createElement(Tooltip, { content: `Resume the ${webex.task.activeMedias[0].mType === MEDIA_MTYPE.CONSULT ? "main" : "consult"} call`, relationship: "label" },
                React.createElement(Button, { onClick: toggleConsultCallHold, appearance: "transparent", icon: React.createElement(PlayRegular, null), size: "medium" })),
            React.createElement(Tooltip, { content: "Complete Transfer", relationship: "label" },
                React.createElement(Button, { onClick: completeTransfer, size: "medium", appearance: "transparent", icon: React.createElement(CallCheckmarkRegular, null) })),
            React.createElement(Tooltip, { content: "Conferance (Merge Calls)", relationship: "label" },
                React.createElement(Button, { onClick: mergeCalls, size: "medium", appearance: "transparent", icon: React.createElement(PeopleCallRegular, null) })),
            React.createElement(Tooltip, { content: "End Consult Call", relationship: "label" },
                React.createElement(Button, { size: "medium", appearance: "transparent", icon: React.createElement(CallDismissRegular, null), className: classes.red, onClick: endConsultCall, disabled: !callControlsAvailable() }))));
    }
    if (((_b = webex.task) === null || _b === void 0 ? void 0 : _b.callState) === CALL_STATE.CONFERENCE) {
        return (React.createElement("div", { className: classes.root },
            React.createElement(Tooltip, { content: conferenceCallHoldTooltipText(), relationship: "label" },
                React.createElement(Button, { onClick: toggleConsultCallHold, appearance: "transparent", icon: conferenceIsHold() ? React.createElement(PlayRegular, null) : React.createElement(PauseRegular, null), size: "medium" })),
            React.createElement(Tooltip, { content: "Complete Transfer (End call for me only)", relationship: "label" },
                React.createElement(Button, { onClick: completeTransfer, size: "medium", appearance: "transparent", icon: React.createElement(CallCheckmarkRegular, null) })),
            React.createElement(Tooltip, { content: "End Call for Consult Party", relationship: "label" },
                React.createElement(Button, { size: "medium", appearance: "transparent", icon: React.createElement(CallDismissRegular, null), className: classes.red, onClick: endConsultCall, disabled: !callControlsAvailable() })),
            React.createElement(Tooltip, { content: "End Call for Everyone", relationship: "label" },
                React.createElement(Button, { size: "medium", appearance: "transparent", icon: React.createElement(CallEndRegular, null), className: classes.red, onClick: endCall, disabled: !callControlsAvailable() }))));
    }
    return (React.createElement("div", { className: classes.root },
        React.createElement(Tooltip, { content: onHoldButtonToolTipText(), relationship: "label" },
            React.createElement(Button, { onClick: toggleHold, appearance: "transparent", icon: onHoldButtonIcon(), size: "medium", disabled: !callControlsAvailable() })),
        React.createElement(Tooltip, { content: "Consult Transfer Call", relationship: "label" },
            React.createElement(Button, { onClick: props.onTransfer, size: "medium", appearance: "transparent", icon: transferIcon, disabled: !callControlsAvailable() })),
        React.createElement(Tooltip, { content: "Forward Call", relationship: "label" },
            React.createElement(Button, { onClick: props.onForward, size: "medium", appearance: "transparent", icon: forwardIcon, disabled: !callControlsAvailable() })),
        React.createElement(Tooltip, { content: "End Call", relationship: "label" },
            React.createElement(Button, { size: "medium", appearance: "transparent", icon: React.createElement(CallEndRegular, null), className: classes.red, onClick: endCall, disabled: !callControlsAvailable() && !cancelCallControlAvailable() }))));
};
