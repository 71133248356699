import React from "react";
import { makeStyles, tokens } from "@fluentui/react-components";
import { CONTACT_DIRECTION } from "../../services/webex/contactcenter/models/IRoutingMessage";
import { AptosFontFamily } from "../../utils/CustomTokens";
import { CopyToClipboard } from "../CopyToClipboard";
const styles = makeStyles({
    callInfoContainer: {
        fontFamily: AptosFontFamily,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "ellipsis",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    callInfoRow: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginBottom: "0.1rem",
        "&:last-child": {
            marginBottom: "0",
        },
    },
    callInfoRowNarrow: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginBottom: "0rem",
    },
    callInfoLabel: {
        fontWeight: "normal",
        minWidth: "60px",
        color: tokens.colorBrandForeground1,
        alignItems: "flex-start",
        alignSelf: "flex-start",
    },
    callInfoValue: {
        flexGrow: 1,
        paddingLeft: "10px",
        display: "flex",
        alignItems: "center",
    },
    callInfoValueAccount: {
        display: "block",
        paddingLeft: "10px",
    },
    loadingMatch: {
        color: "unset"
    },
    singleMatch: {
        color: "#38bd38"
    },
    multiMatch: {
        color: tokens.colorStatusWarningForeground3,
    },
    noMatch: {
        color: tokens.colorStatusDangerForeground3,
    },
});
export const ActiveCallDetails = (props) => {
    var _a;
    const classes = styles();
    const callInfoRowClass = props.isHeldCallSummary ? classes.callInfoRowNarrow : classes.callInfoRow;
    const MAX_MATCHES_TO_DISPLAY = 5;
    const customerAccountsQuery = (_a = props.dynamicsCustomerAccounts) === null || _a === void 0 ? void 0 : _a.accounts;
    const customerAccounts = (customerAccountsQuery === null || customerAccountsQuery === void 0 ? void 0 : customerAccountsQuery.data) || [];
    const customerAccountsQueryLoading = (customerAccountsQuery === null || customerAccountsQuery === void 0 ? void 0 : customerAccountsQuery.loading) || false;
    const customerAccountsQueryError = (customerAccountsQuery === null || customerAccountsQuery === void 0 ? void 0 : customerAccountsQuery.error) || undefined;
    let accountColorStyle = customerAccountsQueryLoading ? classes.loadingMatch : customerAccounts.length === 1 ? classes.singleMatch : customerAccounts.length > 1 ? classes.multiMatch : classes.noMatch;
    let accountDisplayNames;
    if (customerAccountsQueryLoading) {
        accountDisplayNames = [{ text: "Loading...", accountNumber: undefined }];
    }
    else if (customerAccountsQueryError) {
        accountDisplayNames = [{ text: "Error finding a match", accountNumber: undefined }];
    }
    else if ((customerAccounts === null || customerAccounts === void 0 ? void 0 : customerAccounts.length) > 0) {
        accountDisplayNames = customerAccounts.map((account) => {
            var _a, _b;
            return ((_a = account.contacts) === null || _a === void 0 ? void 0 : _a.length) === 1 && ((_b = account.contacts[0].fullname) === null || _b === void 0 ? void 0 : _b.toLowerCase().replace(/[^a-z]/g, "")) !== "primarycontact"
                ? { text: `${account.contacts[0].fullname} - ${account.name}`, accountNumber: account.accountnumber }
                : { text: `${account.name}`, accountNumber: account.accountnumber };
        });
        const matchesCount = accountDisplayNames.length;
        if (matchesCount > MAX_MATCHES_TO_DISPLAY) {
            const moreMatchesCount = matchesCount - MAX_MATCHES_TO_DISPLAY;
            accountDisplayNames = accountDisplayNames.slice(0, MAX_MATCHES_TO_DISPLAY);
            accountDisplayNames.push({ text: `And ${moreMatchesCount} more...`, accountNumber: undefined });
        }
    }
    else {
        accountDisplayNames = [{ text: "No matching accounts", accountNumber: undefined }];
    }
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { className: classes.callInfoContainer },
            props.callDirection === CONTACT_DIRECTION.OUTBOUND && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Call Type:"),
                React.createElement("div", { className: classes.callInfoValue }, props.callDirection))),
            React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, props.isConsultLeg ? "Consult To:" : props.callDirection === CONTACT_DIRECTION.INBOUND ? "Customer:" : "Call To:"),
                React.createElement("div", { className: classes.callInfoValue },
                    props.callerId,
                    props.callerId && React.createElement(CopyToClipboard, { text: props.callerId }))),
            accountDisplayNames && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Account:"),
                React.createElement("div", { className: classes.callInfoValueAccount }, accountDisplayNames.map((i) => {
                    return (React.createElement("div", { className: accountColorStyle },
                        i.text,
                        " ",
                        i.accountNumber && React.createElement("div", { style: { display: "inline-block" } },
                            `(${i.accountNumber})`,
                            React.createElement(CopyToClipboard, { text: i.accountNumber, styles: { marginLeft: 2, color: "#FFFFFF" } }))));
                })))),
            props.customerBranch && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Call To:"),
                React.createElement("div", { className: classes.callInfoValue }, `${props.customerBranch} ${props.customerDepartment}`))),
            props.callDirection === CONTACT_DIRECTION.INBOUND && !props.isHeldCallSummary && props.callFor && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Call To:"),
                React.createElement("div", { className: classes.callInfoValue }, props.callFor))),
            props.toDescription && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Description:"),
                React.createElement("div", { className: classes.callInfoValue }, props.toDescription))),
            props.customerDescription && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Customer:"),
                React.createElement("div", { className: classes.callInfoValue }, props.customerDescription))),
            props.customerBranch && !props.isHeldCallSummary && (React.createElement("div", { className: callInfoRowClass },
                React.createElement("div", { className: classes.callInfoLabel }, "Customer Assigned Branch:"),
                React.createElement("div", { className: classes.callInfoValue }, props.customerBranch))))));
};
