var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// DialPad.tsx
import React, { useState, useMemo } from "react";
import { Button, makeStyles, shorthands, Option, Persona, Combobox, } from "@fluentui/react-components";
import { CallRegular } from "@fluentui/react-icons";
import { useWebexContactCentre } from "../../services/webex/contactcenter/WebexContactCentreProvider";
import { OutboundANISelector } from "../OutboundANISelector";
import { DialPadDigits } from "./DialPadDigits";
import { TRANSFER_RECIPIENT_TYPE } from "../../services/webex/contactcenter/models/Enums";
const CC_CIFV1_WEBEX_CC_CONTACTGROUP_BRANCHES_NAME = "Contacts_BranchNumbers";
const CC_CIFV1_WEBEX_CC_CONTACTGROUP_FIXED_NAME = "Contacts_FixedSpeedTransfers";
if (!CC_CIFV1_WEBEX_CC_CONTACTGROUP_BRANCHES_NAME || !CC_CIFV1_WEBEX_CC_CONTACTGROUP_FIXED_NAME) {
    throw new Error("Missing required environment variables");
}
export var DIAL_TYPE;
(function (DIAL_TYPE) {
    DIAL_TYPE["OUTBOUND"] = "OUTBOUND";
    DIAL_TYPE["FORWARD"] = "FORWARD";
    DIAL_TYPE["CONSULT"] = "CONSULT";
})(DIAL_TYPE || (DIAL_TYPE = {}));
const useStyles = makeStyles({
    container: Object.assign({ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "85%" }, shorthands.padding("20px")),
    inputBox: {
        width: "100%",
    },
    callButton: {
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#444444", // Slightly lighter on hover
            boxShadow: "0 0 1px rgba(108, 99, 255, 1)", // Stronger glow on hover
            color: "#6C63FF",
        },
    },
});
const RESULT_LIMIT = 7;
export const DialPad = (props) => {
    const webex = useWebexContactCentre();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedDisplayName, setSelectedDisplayName] = useState(undefined);
    const [hasOutboundNumberSelected, setHasOutboundNumberSelected] = useState(false);
    const classes = useStyles();
    const handleButtonClick = (digit) => {
        setPhoneNumber((prev) => prev + digit);
    };
    const startCall = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const sanitizedPhoneNumber = phoneNumber.replace(/[^+0-9]/g, "");
        (_a = props.onCallAction) === null || _a === void 0 ? void 0 : _a.call(props);
        switch (props.type) {
            case DIAL_TYPE.OUTBOUND:
                yield webex.makeCall(sanitizedPhoneNumber);
                break;
            case DIAL_TYPE.FORWARD:
                yield webex.forwardCall(webex.task.interaction.interactionId, sanitizedPhoneNumber, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
                break;
            case DIAL_TYPE.CONSULT:
                yield webex.consultCall(webex.task.interaction.interactionId, sanitizedPhoneNumber, selectedDisplayName, TRANSFER_RECIPIENT_TYPE.DIAL_NUMBER);
                break;
        }
    });
    const convertPresenceToFluentUIPresence = (presence) => {
        if (!presence)
            return undefined;
        if (["busy", "out-of-office", "away", "available", "offline", "do-not-disturb", "unknown", "blocked"].includes(presence))
            return presence;
        // Translate Webex presence to FluentUI presence
        switch (presence) {
            case "call":
                return "busy";
            case "meeting":
                return "busy";
            case "presenting":
                return "busy";
            case "OutOfOffice":
                return "out-of-office";
            case "inactive":
                return "away";
            case "active":
                return "available";
            case "DoNotDisturb":
                return "do-not-disturb";
            case "pending":
                return "unknown";
            default:
                return "unknown";
        }
    };
    const memoizedOptions = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        let filteredContacts = [];
        let dialableSearchResults = new Map();
        const searchTerm = phoneNumber.trim().toLowerCase();
        if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.length) === 0) {
            return [];
        }
        const searchEntryPossiblePhoneNumber = searchTerm.replace(/[^0-9]/g, "");
        if (searchEntryPossiblePhoneNumber.match(/^[0-9]+$/)) {
            const searchNumber = searchEntryPossiblePhoneNumber.startsWith("0")
                ? searchEntryPossiblePhoneNumber.substring(1)
                : searchEntryPossiblePhoneNumber.startsWith("61")
                    ? searchEntryPossiblePhoneNumber.substring(2)
                    : searchEntryPossiblePhoneNumber;
            filteredContacts = webex.contacts
                .filter((contact) => { var _a; return (_a = contact.phoneNumbers) === null || _a === void 0 ? void 0 : _a.some((p) => { var _a; return (_a = p.value) === null || _a === void 0 ? void 0 : _a.replace(/[+ ()]/g, "").includes(searchNumber); }); })
                .sort((a, b) => { var _a; return ((_a = a.displayName) === null || _a === void 0 ? void 0 : _a.localeCompare(b.displayName || "")) || 0; })
                .map((contact) => {
                var _a;
                // Filter out phone numbers that don't match the search entry
                const phoneNumbers = (_a = contact.phoneNumbers) === null || _a === void 0 ? void 0 : _a.filter((p) => { var _a; return (_a = p.value) === null || _a === void 0 ? void 0 : _a.replace(/[+ ()]/g, "").includes(searchNumber); });
                return Object.assign(Object.assign({}, contact), { phoneNumbers });
            })
                .slice(0, RESULT_LIMIT);
        }
        else {
            const searchTerms = phoneNumber.toLowerCase().split(" ").filter(Boolean);
            let byEverything = webex.contacts.filter((contact) => {
                const nameParts = [contact.firstName, contact.lastName, contact.displayName, contact.branch, contact.title]
                    .filter(Boolean)
                    .map((s) => s === null || s === void 0 ? void 0 : s.toLowerCase());
                return searchTerms.every((term) => nameParts.some((part) => part === null || part === void 0 ? void 0 : part.includes(term)));
            });
            filteredContacts = byEverything;
        }
        for (const contact of filteredContacts) {
            const phoneNumbers = (_a = contact.phoneNumbers) === null || _a === void 0 ? void 0 : _a.filter((p) => { var _a; return ((_a = p.value) === null || _a === void 0 ? void 0 : _a.length) > 5; });
            if (!phoneNumbers || !Array.isArray(phoneNumbers) || phoneNumbers.length === 0) {
                continue;
            }
            const isBranchContact = (_b = contact.groups) === null || _b === void 0 ? void 0 : _b.some((group) => group.name === CC_CIFV1_WEBEX_CC_CONTACTGROUP_BRANCHES_NAME);
            const isFixedContact = (_c = contact.groups) === null || _c === void 0 ? void 0 : _c.some((group) => group.name === CC_CIFV1_WEBEX_CC_CONTACTGROUP_FIXED_NAME);
            if (phoneNumbers.length === 1) {
                const name = phoneNumbers[0].type === "mobile" ? `${contact.displayName} (Mobile)` : `${contact.displayName}`;
                const phoneNumber = phoneNumbers[0].value;
                dialableSearchResults.set(phoneNumber, {
                    phoneNumber: phoneNumbers[0].value,
                    sortPriority: isBranchContact ? 1 : isFixedContact ? 2 : 3,
                    contactId: contact.id,
                    displayName: name,
                    secondaryText: contact.branch && contact.title ? `${contact.branch} ${contact.title}` : (_e = (_d = contact.branch) !== null && _d !== void 0 ? _d : contact.title) !== null && _e !== void 0 ? _e : "",
                    tertiaryText: phoneNumbers[0].value,
                    status: contact.status ? convertPresenceToFluentUIPresence(contact.status) : null,
                });
            }
            else {
                for (const phoneNumber of phoneNumbers) {
                    const name = `${contact.displayName} (${phoneNumber.type.charAt(0).toUpperCase()}${phoneNumber.type.slice(1)})`;
                    dialableSearchResults.set(phoneNumber.value, {
                        phoneNumber: phoneNumber.value,
                        sortPriority: isBranchContact ? 1 : isFixedContact ? 2 : 3,
                        contactId: contact.id,
                        displayName: name,
                        secondaryText: contact.branch && contact.title ? `${contact.branch} ${contact.title}` : (_g = (_f = contact.branch) !== null && _f !== void 0 ? _f : contact.title) !== null && _g !== void 0 ? _g : "",
                        tertiaryText: phoneNumber.value,
                        status: contact.status ? convertPresenceToFluentUIPresence(contact.status) : null,
                    });
                }
            }
        }
        const optionsToBuildFrom = Array.from(dialableSearchResults.values());
        const sortedOptionsToBuildFrom = optionsToBuildFrom
            .sort((a, b) => {
            // If sortPriority is the same, prioritize exact matches with the search term
            if (a.sortPriority === b.sortPriority) {
                const aStartsWith = a.displayName.toLowerCase().startsWith(searchTerm);
                const bStartsWith = b.displayName.toLowerCase().startsWith(searchTerm);
                if (aStartsWith !== bStartsWith) {
                    return aStartsWith ? -1 : 1; // Prioritize matches that start with searchTerm
                }
                return a.displayName.localeCompare(b.displayName);
            }
            return a.sortPriority - b.sortPriority;
        })
            .slice(0, RESULT_LIMIT);
        const optionsToReturn = sortedOptionsToBuildFrom.map((contact) => (React.createElement(Option, { key: contact.phoneNumber, value: contact.phoneNumber, text: contact.displayName },
            React.createElement(Persona, { name: contact.displayName, secondaryText: contact.secondaryText, tertiaryText: contact.tertiaryText, presence: contact.status ? { status: contact.status } : null }))));
        return optionsToReturn;
    }, [phoneNumber, webex.contacts]);
    const onOptionSelect = (ev, data) => {
        var _a, _b;
        setSelectedOptions(data.selectedOptions);
        setPhoneNumber((_a = data.optionValue) !== null && _a !== void 0 ? _a : "");
        setSelectedDisplayName((_b = data.optionText) !== null && _b !== void 0 ? _b : undefined);
    };
    const isValidNumber = () => {
        const entry = phoneNumber.replace(/[^+0-9]/g, "");
        if (entry.match(/^(\+?61|0[2-9])[0-9]*$/)) {
            return !!entry.match(/^(\+61[2-9]|0[2-9])[0-9]{8}$/);
        }
        return entry.length >= 4;
    };
    const startCallLabel = !hasOutboundNumberSelected
        ? "Select outbound caller ID to call"
        : !isValidNumber()
            ? "Invalid number"
            : props.type === DIAL_TYPE.FORWARD
                ? "Forward Call"
                : props.type === DIAL_TYPE.CONSULT
                    ? "Start Consult"
                    : "Call";
    return (React.createElement("div", { className: classes.container },
        React.createElement(Combobox, { className: classes.inputBox, value: phoneNumber, onOptionSelect: onOptionSelect, selectedOptions: selectedOptions, placeholder: "Search", onInput: (e) => setPhoneNumber(e.target.value), autoComplete: "off", autoCorrect: "off" }, memoizedOptions),
        React.createElement(DialPadDigits, { handleButtonClick: handleButtonClick }),
        React.createElement(React.Fragment, null,
            React.createElement(Button, { className: classes.callButton, appearance: "primary", onClick: startCall, icon: React.createElement(CallRegular, null), disabled: !hasOutboundNumberSelected || !isValidNumber() }, startCallLabel),
            React.createElement(OutboundANISelector, { setHasOutboundNumberSelected: setHasOutboundNumberSelected }))));
};
